.container {
  text-align: center;
  top: 0;
  left: 0;
  width: 100%;
  background-image: url("../photos/pexels-pixabay-265791.jpg");
  z-index: -1;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
}
