.intro {
  text-align: center;
}

.form {
  width: 100%;
  max-width: 750px;
  padding: 15px;
  margin: 0 auto;
}

h3 {
  font-family: "Mindfully";
  font-weight: 400;
  text-transform: none;
  line-height: 0.97;
  letter-spacing: normal;
  font-size: 70px;
}
